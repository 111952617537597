import React, { useState, useEffect, useContext } from "react"
import {
  Main,
  Wrapper,
  BottomFooter,
  SocialBox,
  IconsBox,
  ContactBox,
  InfoBox,
} from "./style"
import logo from "../../images/logo.svg"
import fbIcon from "../../images/fb-icon.png"
import igIcon from "../../images/ig-icon.png"
import ytIcon from "../../images/yt-icon.png"
import tiktokIcon from "../../images/tiktok-icon.png"
import { useStaticQuery, graphql } from "gatsby"
import { ViewContext } from "../../contexts/ViewContext"
import { ResolutionContext } from "../../contexts/ResolutionContext"
import { useIntl } from "gatsby-plugin-react-intl"

const Footer = () => {
  const intl = useIntl()
  const [displayType, setDisplayType] = useContext(ViewContext)
  const [isTablet, isTabletAndPhone, isPhone] = useContext(ResolutionContext)
  const isBrowser = typeof window !== "undefined"
  const [data, setData] = useState({})

  const query = useStaticQuery(graphql`
    query Footer {
      polish: contentfulFooter(node_locale: { eq: "pl-PL" }) {
        copyright
        contact
        socials
        phone
        email
        phone2
      }
      english: contentfulFooter(node_locale: { eq: "en-US" }) {
        copyright
        contact
        socials
        phone
        email
        phone2
      }
    }
  `)

  useEffect(() => {
    if (isBrowser) {
      if (isTablet) {
        setDisplayType("grid")
      }
    }
  }, [isTablet])

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  if (typeof window === `undefined`) {
    return <></>
  }

  return (
    <Main>
      <Wrapper>
        <img src={logo} alt="logo" />
        {isTablet ? (
          <InfoBox>
            <ContactBox>
              <p>{data.contact}</p>
              <p>{data.phone}</p>
              <p>{data.phone2}</p>
              <p>{data.email}</p>
            </ContactBox>
            <SocialBox>
              <p>{intl.formatMessage({ id: "socials" })}</p>
              <IconsBox>
                <a target="_blank" href="https://fb.com/debosz.niepolomice">
                  <img src={fbIcon} alt="facebook" />
                </a>
                <a
                  target="_blank"
                  href="https://instagram.com/debosz_nieruchomosci"
                >
                  <img src={igIcon} alt="instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCdRM-wbNa9tBPznLLLOSvDQ"
                  target="_blank"
                >
                  <img src={ytIcon} alt="youtube" />
                </a>
                <a
                  href="https://www.tiktok.com/@debosz_nieruchomosci"
                  target="_blank"
                >
                  <img src={tiktokIcon} alt="tiktok" />
                </a>
              </IconsBox>
            </SocialBox>
          </InfoBox>
        ) : (
          <>
            <ContactBox>
              <p>{data.contact}</p>

              {isPhone ? (
                <>
                  <p>
                    <a href={`tel:${data.phone}`}>{data.phone}</a>
                  </p>
                  <p>
                    <a href={`tel:${data.phone2}`}>{data.phone2}</a>
                  </p>
                </>
              ) : (
                <>
                  <p>{data.phone}</p>
                  <p>{data.phone2}</p>
                </>
              )}

              <p>{data.email}</p>
            </ContactBox>

            <SocialBox>
              <p>{data.socials}</p>
              <IconsBox>
                <a target="_blank" href="https://fb.com/debosz.niepolomice">
                  <img src={fbIcon} alt="facebook" />
                </a>
                <a
                  target="_blank"
                  href="https://instagram.com/debosz_nieruchomosci"
                >
                  <img src={igIcon} alt="instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCdRM-wbNa9tBPznLLLOSvDQ"
                  target="_blank"
                >
                  <img src={ytIcon} alt="youtube" />
                </a>
                <a
                  href="https://www.tiktok.com/@debosz_nieruchomosci"
                  target="_blank"
                >
                  <img src={tiktokIcon} alt="tiktok" />
                </a>
              </IconsBox>
            </SocialBox>
          </>
        )}
      </Wrapper>
      <BottomFooter>
        <p>{data.copyright}</p>
      </BottomFooter>
    </Main>
  )
}

export default Footer
