/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import ViewProvider from "../contexts/ViewContext"
import ResolutionProvider from "../contexts/ResolutionContext"
import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import Nav from "./Nav"
import Footer from "./Footer"
import FlatsProvider from "../contexts/FlatsContext"
import SearchOptionsProvider from "../contexts/SearchOptionsContext"
import { CustomerChat } from "./CustomerChat"

const GlobalStyle = createGlobalStyle`
  audio::-webkit-media-controls-timeline,
  video::-webkit-media-controls-timeline {
    display: none;
  }
  audio::-webkit-media-controls,
  video::-webkit-media-controls {
    display: none;
  }
  
  * {
    text-decoration: none;
    margin: 0;
    padding: 0;
    font-weight: 400;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
    font-size: 62.5%;
    

    @media (min-width: 1400px) {
      font-size: calc(62.5% + 0.1em );
    }
    
    img {
      user-select: none;
    }

  }
   
  .select-search__value{
background-color: #111111;
  }

  body {
    background: #030303;
    margin: 0;
    overflow-x: hidden;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // React.useEffect(() => {
  //   document.addEventListener("contextmenu", event => {
  //     event.preventDefault()
  //   })
  // }, [])

  return (
    <ResolutionProvider>
      <ViewProvider>
        <SearchOptionsProvider>
          <FlatsProvider>
            <GlobalStyle />
            <Nav />
            <main>{children}</main>
            <Footer />
          </FlatsProvider>
        </SearchOptionsProvider>
      </ViewProvider>
    </ResolutionProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
