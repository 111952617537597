import React, { createContext, useState, useEffect } from "react"

export const ResolutionContext = createContext(null)
const isBrowser = typeof window !== "undefined"
const tabletInitialValue =
  isBrowser &&
  window.matchMedia("(min-width:800px) and (max-width:1149px)").matches

const tabletAndPhoneInitialValue =
  isBrowser && window.matchMedia("(max-width:1149px)").matches

const phoneInitialValue =
  isBrowser && window.matchMedia("(max-width:799px)").matches

const ResolutionProvider = ({ children }) => {
  const [isTablet, toggleTablet] = useState(tabletInitialValue)
  const [isTabletAndPhone, toggleTabletAndPhone] = useState(
    tabletAndPhoneInitialValue
  )
  const [isPhone, togglePhone] = useState(phoneInitialValue)

  useEffect(() => {
    if (isBrowser) {
      window.dispatchEvent(new Event("resize"))

      toggleTabletAndPhone(window.matchMedia("(max-width:1149px)").matches)

      toggleTablet(
        window.matchMedia("(min-width:800px) and (max-width:1149px)").matches
      )

      togglePhone(window.matchMedia("(max-width:799px)").matches)

      const listener = () => {
        toggleTabletAndPhone(window.matchMedia("(max-width:1149px)").matches)

        toggleTablet(
          window.matchMedia("(min-width:800px) and (max-width:1149px)").matches
        )
        togglePhone(window.matchMedia("(max-width:799px)").matches)
      }

      window.addEventListener("resize", listener, true)

      return () => window.removeEventListener("resize", listener, true)
    }
  }, [isBrowser])

  return (
    <ResolutionContext.Provider value={[isTablet, isTabletAndPhone, isPhone]}>
      {children}
    </ResolutionContext.Provider>
  )
}

export default ResolutionProvider
