import React, { createContext, useState, useEffect } from "react"
import axios from "axios"
import { graphql, useStaticQuery } from "gatsby"

export const FlatsContext = createContext(null)
const FlatsProvider = ({ children }) => {
  const query = useStaticQuery(graphql`
    query all {
      allOffers {
        nodes {
          offers
        }
      }
    }
  `)

  const isBrowser = typeof window !== "undefined"
  const [flats, setFlats] = useState([])
  const [loading, toggleLoading] = useState(true)
  const [filteredFlats, setFilteredFlats] = useState([])

  const getFlatsFromDatabase = async () => {
    toggleLoading(true)
    try {
      // const response = await axios.get(
      //   `${process.env.GATSBY_FIREBASE_DB_URL}/offers.json`
      // )

      const response = {
        data: JSON.parse(query.allOffers.nodes[0].offers),
      }

      for (const [key, value] of Object.entries(response.data)) {
        setFlats(prev => [...prev, value])
      }
      toggleLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isBrowser) {
      getFlatsFromDatabase()
    }
  }, [])

  useEffect(() => {
    if (isBrowser) {
      if (!loading) {
        setFilteredFlats(flats)
      }
    }
  }, [loading, flats])

  return (
    <FlatsContext.Provider
      value={[flats, loading, filteredFlats, setFilteredFlats]}
    >
      {children}
    </FlatsContext.Provider>
  )
}

export default FlatsProvider
