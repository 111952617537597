import styled from "styled-components"

export const Main = styled.div`
  height: 30px;
  width: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  display: none;
  cursor: pointer;
  z-index: 99;

  @media (max-width: 1149px) {
    display: flex;
  }

  div {
    background: linear-gradient(90deg, #eae69c 0%, #bb913c 100%);
    height: 3px;
    width: 100%;
    border-radius: 2px;

    transition: width 0.25s, height 0.25s, opacity 0.25s, transform 0.25s;
  }

  div:nth-child(1) {
    transform-origin: 5% 50%;
  }

  div:nth-child(3) {
    transform-origin: 3% 50%;
  }

  ${props => props.isActive ? `
    div {
      height: 4px;
    }

    div:nth-child(2) {
      opacity: 0;
    }

    div:nth-child(1) {
      transform: rotate(45deg);
      width: 141.42%;
    }

    div:nth-child(3) {
      transform: rotate(-45deg);
      width: 141.42%;
    }
    ` : ''}
`
