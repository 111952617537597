import React, { useEffect, useState } from "react"
import {
  RightSideNav,
  Main,
  Wrapper,
  ButtonBox,
  Menu,
  LeftSide,
  RightSide,
  PhoneBox,
  LanguageBox,
  MenuWrapper,
  Logo,
  ServicesMenu,
  ServicesLink,
  ContactServicesLink,
  ServicesSpanMobileBox,
  ContactMenu,
} from "./style"
import logo from "../../images/logo.png"
import MenuIcon from "./MenuIcon"
import { changeLocale, Link, navigate, useIntl } from "gatsby-plugin-react-intl"
import { useStaticQuery, graphql } from "gatsby"

const Nav = () => {
  const [isOpen, toggleOpen] = useState(false)
  const [isServicesMenuOpen, toggleServicesMenuOpen] = useState(false)
  const [isContactMenuOpen, toggleContactMenuOpen] = useState(false)
  const intl = useIntl()
  const lang = intl.locale
  const isBrowser = typeof window !== "undefined"
  const [data, setData] = useState({})
  const [dataServices, setDataServices] = useState({})
  const [dataMovies, setDataMovies] = useState({})
  const [dataContact, setDataContact] = useState({})
  const inistialScrollValue =
    isBrowser && document.scrollingElement.scrollTop >= 1
  const [isScrolled, toggleScrolled] = useState(inistialScrollValue)
  const closeMenu = () => {
    toggleOpen(false)
  }

  const openServciesMenu = () => {
    toggleServicesMenuOpen(true)
    toggleScrolled(true)
  }

  const closeServicesMenu = () => {
    toggleServicesMenuOpen(false)
    if (!inistialScrollValue) {
      toggleScrolled(false)
    }
  }

  const openContactMenu = () => {
    toggleContactMenuOpen(true)
    toggleScrolled(true)
  }

  const closeContactMenu = () => {
    toggleContactMenuOpen(false)
    if (!inistialScrollValue) {
      toggleScrolled(false)
    }
  }

  const routeMap = {
    "/en/": "/",
    "/en/services/purchase/": "/uslugi/zakup/",
    "/en/services/selling/": "/uslugi/sprzedaz/",
    "/en/services/rent/": "/uslugi/wynajem/",
    "/en/services/tenancy/": "/uslugi/najem/",
    "/en/services/valuation/": "/uslugi/wycena/",
    "/en/services/financing/": "/uslugi/finansowanie/",
    "/en/services/movies/": "/uslugi/filmy/",
    "/en/search/": "/wyszukiwarka/",
    "/en/about-us/": "/o-nas/",
    "/en/career/": "/kariera/",
    "/en/contact/": "/kontakt/",
    "/en/contact/niepolomice/": "/kontakt/niepolomice/",
    "/en/contact/bochnia/": "/kontakt/bochnia/",
    "/en/services/": "/uslugi/",
    "/en/privacy_policy/": "/polityka_prywatnosci/",
  }

  const f = obj => Object.fromEntries(Object.entries(obj).map(a => a.reverse()))

  const routeMapEN = f(routeMap)

  const query = useStaticQuery(graphql`
    query Nav {
      polish: contentfulNav(node_locale: { eq: "pl-PL" }) {
        search
        services
        carrier
        aboutUs
        contact
        phone
      }
      english: contentfulNav(node_locale: { eq: "en-US" }) {
        search
        services
        carrier
        aboutUs
        contact
        phone
      }
      polish2: contentfulServices(node_locale: { eq: "pl-PL" }) {
        service1
        service2
        service3
        service4
        service5
        service6
      }

      english2: contentfulServices(node_locale: { eq: "en-US" }) {
        service1
        service2
        service3
        service4
        service5
        service6
      }
      polish3: contentfulMoviesServices(node_locale: { eq: "pl-PL" }) {
        slider1
      }
      english3: contentfulMoviesServices(node_locale: { eq: "en-US" }) {
        slider1
      }
      polish4: contentfulContact(node_locale: { eq: "pl-PL" }) {
        header
      }
      english4: contentfulContact(node_locale: { eq: "en-US" }) {
        header
      }
    }
  `)

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
        setDataServices(query.english2)
        setDataMovies(query.english3)
        setDataContact(query.english4)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
        setDataServices(query.polish2)
        setDataMovies(query.polish3)
        setDataContact(query.polish4)
      }
    }
  }, [intl])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden"
    } else document.body.style.overflowY = "visible"
  }, [isOpen])

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scroll = document.scrollingElement.scrollTop

      if (scroll >= 1) {
        toggleScrolled(true)
      } else {
        toggleScrolled(false)
      }
    })
  }, [])

  const activeNavButtonStyle = {
    fontWeight: "800",
  }

  return (
    <Main isScrolled={isScrolled}>
      <Menu visible={isOpen}>
        <LeftSide onClick={() => closeMenu()} visible={isOpen} />
        <RightSide visible={isOpen}>
          <MenuWrapper>
            <Link
              to={lang === "pl" ? "/wyszukiwarka/" : "/search/"}
              onClick={() => closeMenu()}
              activeStyle={activeNavButtonStyle}
            >
              {data.search}
            </Link>

            <ServicesSpanMobileBox>
              <Link
                to={lang === "pl" ? "/uslugi/" : "/services/"}
                onClick={() => closeMenu()}
                activeStyle={activeNavButtonStyle}
              >
                {data.services}
              </Link>
              <Link
                to={lang === "pl" ? "/uslugi/sprzedaz/" : "/services/selling/"}
                activeStyle={activeNavButtonStyle}
              >
                {dataServices.service1}
              </Link>
              <Link
                to={lang === "pl" ? "/uslugi/zakup/" : "/services/purchase/"}
                activeStyle={activeNavButtonStyle}
              >
                {dataServices.service2}
              </Link>
              <Link
                to={lang === "pl" ? "/uslugi/wynajem/" : "/services/rent/"}
                activeStyle={activeNavButtonStyle}
              >
                {dataServices.service3}
              </Link>
              <Link
                to={lang === "pl" ? "/uslugi/najem/" : "/services/tenancy/"}
                activeStyle={activeNavButtonStyle}
              >
                {dataServices.service4}
              </Link>
              <Link
                to={lang === "pl" ? "/uslugi/wycena/" : "/services/valuation/"}
                activeStyle={activeNavButtonStyle}
              >
                {dataServices.service5}
              </Link>
              <Link
                to={
                  lang === "pl"
                    ? "/uslugi/finansowanie/"
                    : "/services/financing/"
                }
                activeStyle={activeNavButtonStyle}
              >
                {dataServices.service6}
              </Link>

              <Link
                to={lang === "pl" ? "/uslugi/filmy/" : "/services/movies/"}
                activeStyle={activeNavButtonStyle}
              >
                {dataMovies.slider1}
              </Link>
            </ServicesSpanMobileBox>
            <Link
              to={lang === "pl" ? "/o-nas/" : "/about-us/"}
              onClick={() => closeMenu()}
              activeStyle={activeNavButtonStyle}
            >
              {data.aboutUs}
            </Link>
            <Link
              to={lang === "pl" ? "/kariera/" : "/career/"}
              onClick={() => closeMenu()}
              activeStyle={activeNavButtonStyle}
            >
              {data.carrier}
            </Link>

            <Link
              to={lang === "pl" ? "/kontakt/" : "/contact"}
              activeStyle={activeNavButtonStyle}
            >
              {data.contact}
            </Link>
            <Link
              onClick={() => closeMenu()}
              to={
                lang === "pl"
                  ? "/kontakt/niepolomice/"
                  : "/contact/niepolomice/"
              }
              activeStyle={activeNavButtonStyle}
              style={{
                textTransform: "capitalize",
                position: "relative",
                bottom: "20px",
                fontSize: "1.4rem",
                textAlign: "center",
              }}
            >
              {dataContact.header}
            </Link>
            <Link
              onClick={() => closeMenu()}
              to={lang === "pl" ? "/kontakt/bochnia/" : "/contact/bochnia/"}
              activeStyle={activeNavButtonStyle}
              style={{
                textTransform: "capitalize",
                position: "relative",
                bottom: "20px",
                fontSize: "1.4rem",
                textAlign: "center",
              }}
            >
              Biuro Nieruchomości Bochnia
            </Link>

            <LanguageBox locale={intl.locale}>
              <p
                onClick={() => {
                  const currentPage = window.location.pathname // service

                  if (currentPage.includes("oferta")) {
                    changeLocale("pl")
                  } else {
                    changeLocale("pl", routeMap[currentPage])
                  }
                }}
              >
                PL
              </p>
              <p>| </p>
              <p
                onClick={() => {
                  const currentPage = window.location.pathname

                  if (window.location.pathname.includes("oferta")) {
                    changeLocale("en")
                  } else {
                    changeLocale(
                      "en",
                      routeMapEN[currentPage].replace("/en", "")
                    )
                  }
                }}
              >
                EN
              </p>
            </LanguageBox>
          </MenuWrapper>
        </RightSide>
      </Menu>
      <Wrapper>
        <Logo to="/">
          <img src={logo} alt="logo" />
        </Logo>

        <RightSideNav>
          <ButtonBox>
            <Link
              to={lang === "pl" ? "/wyszukiwarka/" : "/search/"}
              activeStyle={activeNavButtonStyle}
            >
              {data.search}
            </Link>
            <Link
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onMouseOver={openServciesMenu}
              onMouseLeave={closeServicesMenu}
              to={lang === "pl" ? "/uslugi/" : "/services/"}
              activeStyle={activeNavButtonStyle}
            >
              {data.services}

              <ServicesMenu
                isServicesMenuOpen={isServicesMenuOpen}
                onMouseLeave={closeServicesMenu}
              >
                <ServicesLink
                  activeStyle={activeNavButtonStyle}
                  to={
                    lang === "pl" ? "/uslugi/sprzedaz/" : "/services/selling/"
                  }
                >
                  {dataServices.service1}
                </ServicesLink>
                <ServicesLink
                  activeStyle={activeNavButtonStyle}
                  to={lang === "pl" ? "/uslugi/zakup/" : "/services/purchase/"}
                >
                  {dataServices.service2}
                </ServicesLink>
                <ServicesLink
                  activeStyle={activeNavButtonStyle}
                  to={lang === "pl" ? "/uslugi/wynajem/" : "/services/rent/"}
                >
                  {dataServices.service3}
                </ServicesLink>
                <ServicesLink
                  activeStyle={activeNavButtonStyle}
                  to={lang === "pl" ? "/uslugi/najem/" : "/services/tenancy/"}
                >
                  {dataServices.service4}
                </ServicesLink>
                <ServicesLink
                  activeStyle={activeNavButtonStyle}
                  to={
                    lang === "pl" ? "/uslugi/wycena/" : "/services/valuation/"
                  }
                >
                  {dataServices.service5}
                </ServicesLink>
                <ServicesLink
                  activeStyle={activeNavButtonStyle}
                  to={
                    lang === "pl"
                      ? "/uslugi/finansowanie/"
                      : "/services/financing/"
                  }
                >
                  {dataServices.service6}
                </ServicesLink>

                <ServicesLink
                  activeStyle={activeNavButtonStyle}
                  to={lang === "pl" ? "/uslugi/filmy/" : "/services/movies/"}
                >
                  {dataMovies.slider1}
                </ServicesLink>
              </ServicesMenu>
            </Link>
            <Link
              to={lang === "pl" ? "/o-nas/" : "/about-us/"}
              activeStyle={activeNavButtonStyle}
            >
              {data.aboutUs}
            </Link>
            <Link
              to={lang === "pl" ? "/kariera/" : "/career/"}
              activeStyle={activeNavButtonStyle}
            >
              {data.carrier}
            </Link>

            <Link
              activeStyle={activeNavButtonStyle}
              to={lang === "pl" ? "/kontakt/" : "/contact"}
              onMouseOver={openContactMenu}
              onMouseLeave={closeContactMenu}
            >
              {data.contact}

              <ContactMenu
                isContactMenuOpen={isContactMenuOpen}
                onMouseLeave={closeContactMenu}
              >
                <ContactServicesLink
                  to={
                    lang === "pl"
                      ? "/kontakt/niepolomice/"
                      : "/contact/niepolomice/"
                  }
                  activeStyle={activeNavButtonStyle}
                >
                  {dataContact.header}
                </ContactServicesLink>
                <ContactServicesLink
                  to={lang === "pl" ? "/kontakt/bochnia/" : "/contact/bochnia/"}
                  activeStyle={activeNavButtonStyle}
                >
                  Biuro Nieruchomości Bochnia
                </ContactServicesLink>
              </ContactMenu>
            </Link>
          </ButtonBox>
          <PhoneBox>
            <p>{data.phone}</p>
          </PhoneBox>
          <LanguageBox locale={intl.locale}>
            <p
              onClick={() => {
                const currentPage = window.location.pathname

                if (currentPage.includes("oferta")) {
                  changeLocale("pl")
                } else {
                  changeLocale("pl", routeMap[currentPage])
                }
              }}
            >
              PL
            </p>
            <p>| </p>
            <p
              onClick={() => {
                const currentPage = window.location.pathname

                if (window.location.pathname.includes("oferta")) {
                  changeLocale("en")
                } else {
                  changeLocale("en", routeMapEN[currentPage].replace("/en", ""))
                }
              }}
            >
              EN
            </p>
          </LanguageBox>
          <MenuIcon toggleOpen={toggleOpen} isOpen={isOpen} />
        </RightSideNav>
      </Wrapper>
    </Main>
  )
}

export default Nav
