import styled from "styled-components"
import { Link } from "gatsby-plugin-react-intl"

export const Main = styled.div`
  height: 8.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 6;
  background: ${props => (props.isScrolled ? "#030303" : "transparent")};
`

export const Logo = styled(Link)`
  z-index: 99;
  transform: scale(1.3);

  @media (max-width: 799px) {
    transform: scale(1);
  }
`

export const Wrapper = styled.div`
  width: 83.3%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.88%;
  }

  a {
    img {
      height: 46px;
      width: 150px;
      z-index: 99;
      cursor: pointer;

      @media (min-width: 1700px) {
        height: 60px;
        width: 200px;
      }
    }
  }
`

export const RightSideNav = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.15s;
`

export const PhoneBox = styled.div`
  height: 100%;
  width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  @media (max-width: 1149px) {
    margin-right: 80px;
  }

  @media (max-width: 799px) {
    display: none;
  }

  p {
    z-index: 5;
    font-weight: 800;
    font-size: 1.4rem;
    line-height: 2.1rem;
    background: linear-gradient(90deg, #bf9945, #cdb161);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const LanguageBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  gap: 5px;
  z-index: 5;

  @media (max-width: 799px) {
    position: absolute;
    bottom: 20px;
  }

  @media (max-width: 1149px) {
    display: none;
  }

  p:first-child {
    font-weight: ${props => (props.locale === "pl" ? "800" : "500")};
    background: linear-gradient(90deg, #cdb161, #d3bc7d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:hover {
      text-decoration: underline;
      font-weight: 800;
    }
  }

  p:nth-child(2) {
    cursor: default;
  }

  p:nth-child(3) {
    background: #d3bc7d;
    font-weight: ${props => (props.locale === "en" ? "800" : "500")};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &:hover {
      text-decoration: underline;
      font-weight: 800;
    }
  }

  p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;
    //background: linear-gradient(
    //  270.01deg,
    //  #d8c577 0.01%,
    //  #bb913c 29.17%,
    //  #eae69c 64.58%,
    //  #b6872d 97.39%
    //);
    background: #d8c577; /* Old browsers */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #d8c577 0.01%,
      #bb913c 29.17%,
      #eae69c 64.58%,
      #b6872d 97.39%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #d8c577 0.01%,
      #bb913c 29.17%,
      #eae69c 64.58%,
      #b6872d 97.39%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      #d8c577 0.01%,
      #bb913c 29.17%,
      #eae69c 64.58%,
      #b6872d 97.39%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8c577', endColorstr='#b6872d',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    position: relative;

    @media (max-width: 1149px) {
      font-size: 1.4rem;
      line-height: 2.1rem;
      letter-spacing: 0.06em;
    }
  }
`

export const ButtonBox = styled.div`
  height: 100%;
  min-width: 52rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  letter-spacing: 1px;
  gap: 40px;
  z-index: 5;
  margin-right: 20px;
  text-align: center;

  @media (max-width: 1149px) {
    display: none;
    background: #eae69c; /* Old browsers */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  & > p {
    text-decoration: none;
    font-size: 1.4rem;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 2.1rem;
    cursor: pointer;
    position: relative;
    background: #d2bc6d; /* Old browsers */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #d2bc6d 0%,
      #bf9945 100%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #d2bc6d 0%,
      #bf9945 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      #d2bc6d 0%,
      #bf9945 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d2bc6d', endColorstr='#bf9945',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & > a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 1.4rem;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 2.1rem;
    cursor: pointer;
    position: relative;

    background: #eae69c; /* Old browsers */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:nth-of-type(1) {
      min-width: 150px;
      background: #b6872d; /* Old browsers */
      background: -moz-radial-gradient(
        center,
        ellipse cover,
        #b6872d 0%,
        #cbae5b 100%
      ); /* FF3.6-15 */
      background: -webkit-radial-gradient(
        center,
        ellipse cover,
        #b6872d 0%,
        #cbae5b 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(
        ellipse at center,
        #b6872d 0%,
        #cbae5b 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b6872d', endColorstr='#cbae5b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:nth-of-type(2) {
      min-width: 67px;
      background: #cbae5b; /* Old browsers */
      background: -moz-radial-gradient(
        center,
        ellipse cover,
        #cbae5b 0%,
        #dfd184 100%
      ); /* FF3.6-15 */
      background: -webkit-radial-gradient(
        center,
        ellipse cover,
        #cbae5b 0%,
        #dfd184 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(
        ellipse at center,
        #cbae5b 0%,
        #dfd184 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbae5b', endColorstr='#dfd184',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:nth-of-type(3) {
      min-width: 58px;
      background: #dfd184; /* Old browsers */
      background: -moz-radial-gradient(
        center,
        ellipse cover,
        #dfd184 0%,
        #e4dc91 100%
      ); /* FF3.6-15 */
      background: -webkit-radial-gradient(
        center,
        ellipse cover,
        #dfd184 0%,
        #e4dc91 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(
        ellipse at center,
        #dfd184 0%,
        #e4dc91 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfd184', endColorstr='#e4dc91',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:nth-of-type(4) {
      min-width: 80px;
      background: #e4dc91; /* Old browsers */
      background: -moz-radial-gradient(
        center,
        ellipse cover,
        #e4dc91 0%,
        #d2bc6d 100%
      ); /* FF3.6-15 */
      background: -webkit-radial-gradient(
        center,
        ellipse cover,
        #e4dc91 0%,
        #d2bc6d 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(
        ellipse at center,
        #e4dc91 0%,
        #d2bc6d 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4dc91', endColorstr='#d2bc6d',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:nth-of-type(5) {
      min-width: 90px;
      background: #d2bc6d; /* Old browsers */
      background: -moz-radial-gradient(
        center,
        ellipse cover,
        #d2bc6d 0%,
        #bf9945 100%
      ); /* FF3.6-15 */
      background: -webkit-radial-gradient(
        center,
        ellipse cover,
        #d2bc6d 0%,
        #bf9945 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(
        ellipse at center,
        #d2bc6d 0%,
        #bf9945 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d2bc6d', endColorstr='#bf9945',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    transform: all 0.15s;

    &:hover {
      transform: all 0.15s;
      font-weight: 700;
    }
  }
`

export const Menu = styled.div`
  @media (min-width: 1150px) {
    display: none;
  }

  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  overflow: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  top: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
  z-index: 99;

  ${props => (!props.visible ? "pointer-events: none;" : "")}

  @media (max-width: 799px) {
    background: ${props => props.visible && "#030303"};
  }
`

export const LeftSide = styled.div`
  min-height: 500px;
  height: 100%;
  width: 27.5%;
  z-index: 99;

  ${props =>
    !props.visible
      ? `
    opacity: 0;
  `
      : ""}

  @media (max-width: 799px) {
    width: 0;
  }
`

export const RightSide = styled.div`
  position: relative;
  left: 0;

  background: #030303;
  height: 100%;
  width: 72.5%;
  display: flex;

  justify-content: flex-end;
  align-items: flex-start;
  padding: 110px 70px 0 0;
  opacity: 1;
  transition: left 0.25s, opacity 0.25s;

  z-index: 99;

  ${props =>
    !props.visible
      ? `
    left: 100%;
    opacity: 0;
  `
      : ""}

  @media (max-width: 1149px) {
    padding: 110px 7.75% 0 0;
  }

  @media (max-width: 799px) {
    padding: 0;
    width: 100%;
    position: unset;
    justify-content: center;
    align-items: center;
    min-height: 500px;
  }

  @media (max-height: 700px) and (max-width: 799px) {
    /* padding-top: 100px; */
  }

  @media (max-width: 1149px) and (min-width: 800px) {
    min-height: 500px;
  }

  @media (max-height: 600px) and (max-width: 799px) {
    margin-top: 50px;
    min-height: 900px;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-left: 60%;

  ${LanguageBox} {
    display: flex;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0;
    margin-top: -25px;
  }

  @media (max-width: 1149px) {
    background: #b6872d; /* Old browsers */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #b6872d 25%,
      #eae69c 6.77%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #b6872d 25%,
      #eae69c 6.77%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      #b6872d 25%,
      #eae69c 6.77%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b6872d', endColorstr='#ce9638',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 799px) {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  & > p {
    text-decoration: none;
    font-size: 1.6rem;
    color: #fdfdfd;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 2.1rem;
    transition: color 0.1s ease-in-out;
    margin: 20px 0 0 0;
  }
  & > a {
    text-decoration: none;
    font-size: 1.6rem;
    color: #fdfdfd;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 2.1rem;
    transition: color 0.1s ease-in-out;
    cursor: pointer;
    margin: 15px 0 0 0;

    &:last-of-type {
      margin: 0;
      margin-top: -20px;
    }

    background: #eae69c; /* Old browsers */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: 450px) and (max-width: 799px) {
      font-size: calc(1.4rem + 7em);
    }

    &:first-child {
      margin: 0;
    }

    &:hover {
      color: #ce9638;
      transition: color 0.1s ease-in-out;
    }
  }

  /* @media (max-width: 1149px) and (min-width: 800px) and (max-height: 450px) {
    margin-top: 50px;
  }

  @media (max-width: 1149px) and (min-width: 800px) and (max-height: 300px) {
    margin-top: 100px;
  }

  @media (max-height: 500px) and (max-width: 799px) {
    margin-top: 30%;
  } */
`

export const ServicesMenu = styled.div`
  display: ${props => (props.isServicesMenuOpen ? "flex" : "none")};
  flex-direction: column;
  gap: 5px;
  width: 280px;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  top: 20.5px;
  background: #030303;
  z-index: 6;
`

export const ServicesLink = styled(Link)`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  letter-spacing: 0.06em;
  transition: all 0.05s;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }

  &:hover {
    font-weight: 700;
    transition: all 0.05s;
  }

  //background: linear-gradient(
  //  180deg,
  //  #eae69c 6.77%,
  //  #b6872d 25%,
  //  #eae69c 55.73%,
  //  #ce9638 100%
  //);

  background: #eae69c; /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #eae69c 6.77%,
    #b6872d 25%,
    #eae69c 55.73%,
    #ce9638 100%
  ); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #eae69c 6.77%,
    #b6872d 25%,
    #eae69c 55.73%,
    #ce9638 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    ellipse at center,
    #eae69c 6.77%,
    #b6872d 25%,
    #eae69c 55.73%,
    #ce9638 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eae69c', endColorstr='#ce9638',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const ContactServicesLink = styled(ServicesLink)`
  text-transform: none;

  &::first-letter {
    text-transform: unset;
  }
`

export const ServicesSpanMobileBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  gap: 22px;
  left: 15%;

  a {
    &:first-of-type {
      font-size: 1.6rem;
      line-height: 2.1rem;
      margin-bottom: 5px;
      font-weight: 500;
      text-transform: unset;
    }

    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }

    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.1rem;
    letter-spacing: 0.06em;
    text-align: center;
    color: #fdfdfd;

    background: #eae69c; /* Old browsers */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 1149px) {
    //background: linear-gradient(
    //        180deg,
    //        #eae69c 0%,
    //        #b6872d 0.01%,
    //        #eae69c 55.73%,
    //        #ce9638 100%
    //);

    background: #eae69c; /* Old browsers */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      #eae69c 0%,
      #b6872d 0.01%,
      #eae69c 55.73%,
      #ce9638 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1000000000;
  }

  @media (max-width: 799px) {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    position: unset;
    gap: 5px;
  }
`

export const ContactMenu = styled.div`
  flex-direction: column;
  min-height: 45px;
  gap: 5px;
  width: 290px;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  position: absolute;
  top: 20.5px;
  right: -105px;
  background: #030303;
  z-index: 6;
  display: ${props => (props.isContactMenuOpen ? "flex" : "none")};
`
