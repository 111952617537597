import styled from "styled-components"

export const Main = styled.div`
  height: 210px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #111111;

  @media (max-width: 1149px) {
    height: 261px;
  }

  @media (max-width: 799px) {
    height: 454px;
  }
`

export const Wrapper = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > img {
    height: 72px;
    width: 234px;
  }

  @media (max-width: 1149px) {
    width: 76.25%;
    height: 211px;
  }

  @media (max-width: 799px) {
    height: 100%;
    width: 64.5%;
    flex-direction: column;
    justify-content: space-evenly;
  }
`

export const InfoBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

export const ContactBox = styled.div`
  min-width: 23.4rem;
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  p {
    font-weight: 400;
    text-align: center;
    color: #fdfdfd;

    a {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.55rem;
      letter-spacing: 0.12em;
      text-align: center;
      color: #fdfdfd;
    }

    &:nth-child(1) {
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.05em;
    }

    &:nth-child(2),
    &:nth-child(3) {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.55rem;
      letter-spacing: 0.12em;

      @media (max-width: 779px) {
        background: linear-gradient(
          269.66deg,
          #ce9638 -15.04%,
          #eae69c 9.04%,
          #ce9638 56.57%,
          #eae69c 106.63%
        );
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    &:nth-child(4) {
      letter-spacing: 0.06em;
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }
`

export const SocialBox = styled.div`
  min-width: 23.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 1149px) {
    gap: 10px;
  }

  @media (max-width: 779px) {
    gap: 20px;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0.05em;
    font-weight: 400;
    color: #fdfdfd;
  }
`

export const IconsBox = styled.div`
  width: 15.4rem;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 1149px) {
    margin-bottom: unset;
  }

  a {
    width: 35px;
    height: 35px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const BottomFooter = styled.div`
  width: 100%;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;

  p {
    color: #616161;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0;
    text-align: center;
  }

  @media (max-width: 799px) {
    height: auto;
    padding: 13px 0;
  }
`
