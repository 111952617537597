import React, { createContext, useState, useEffect } from "react"

export const ViewContext = createContext(null)
const isBrowser = typeof window !== "undefined"
const initialValue = (isBrowser && "grid") || "grid"

const ViewProvider = ({ children }) => {
  const [displayType, setDisplayType] = useState(initialValue)

  useEffect(() => {
    if (window.matchMedia("(min-width:1150px)").matches) {
      setDisplayType("flex")
    } else {
      setDisplayType("grid")
    }
  }, [])

  return (
    <ViewContext.Provider value={[displayType, setDisplayType]}>
      {children}
    </ViewContext.Provider>
  )
}

export default ViewProvider
