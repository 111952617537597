import React from "react"
import { Main } from "./style"

const MenuIcon = ({ toggleOpen, isOpen }) => {
  return (
    <Main onClick={() => toggleOpen(!isOpen)} isActive={isOpen}>
      <div />
      <div />
      <div />
    </Main>
  )
}

export default MenuIcon
