import React, { createContext, useState } from "react"
export const SearchOptionsContext = createContext(null)

const SearchOptionsProvider = ({ children }) => {
  const [searchData, setSearchData] = useState({
    propertyType: "",
    transactionType: "",
    location: "",
    priceFrom: "",
    priceTo: "",
    floorageFrom: "",
    floorageTo: "",
  })

  // type TSortingType = {
  //   column: "PRICE" | "DATE",
  //   direction: "ASC" | "DESC"
  // };
  const [sortingType, setSortingType] = useState({
    column: "DATE",
    direction: "DESC",
  })

  return (
    <SearchOptionsContext.Provider
      value={[searchData, setSearchData, sortingType, setSortingType]}
    >
      {children}
    </SearchOptionsContext.Provider>
  )
}

export default SearchOptionsProvider
